html{
  background-color: #f5f5f5;
}
body {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  font-family: "Urbanist", sans-serif;
}
.App {
  min-width: 60vw;
  padding: 2rem;
  border-radius: 24px;
  color: #022422;
}
nav {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: start;
}
nav button {
  font-size: 12px;
  background-color: transparent;
  border: none;
  letter-spacing: 3.6px;
  line-height: 18px;
  color: #3EA19D;
  text-transform: uppercase;
  cursor: pointer;
  font-weight: lighter;

}
.navbar-active-button,nav button:hover {
  font-weight: 900;
}

.nav-hr {
  border-color: #E7EDEC;
}
.page-handler {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2rem;
}
.left{
  background-color: #ffffff;
  padding: 3rem;
  border-radius: 12px;
}
.page-switching-buttons {
  margin-top: 2.5rem;
  display: flex;
}
.page-switching-buttons button {
  font-family: "DM Sans", sans-serif;
  font-size: 14px;
  cursor: pointer;
  background-color: transparent;
  color: #034844;
  border: none;
  display: flex;
  align-items: center;
  gap: .5rem;
}

.page-switching-buttons button:hover {
  font-weight: bold;
}
.page-title {
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0;
  margin-block-end: 0.5em;
}
.dynamic-page{
  min-width: 395px;
  
}
.dynamic-page-input-title {
  color: #384E4C;
  font-weight: 400;
  font-size: 12px;
  line-height: 26px;
  letter-spacing: 0;
  margin-block-end: 0rem;
}
.dynamic-page-input-end{
  font-family: "DM Sans", sans-serif;
  color: #384E4C;
  font-weight: 600;
  font-size: 12px;
  line-height: 26px;
  letter-spacing: 0;
}
.dynamic-page input {
  width: 150px;
  height: 40px;
  border-radius: 10px;
  background-color: rgb(247, 249, 249);
  border: 1px solid rgb(239, 236, 235);
  outline: none;
  font-size: 18px;
  margin-bottom: 2.5rem;
}
.dynamic-page-data {
  font-family: "DM Sans", sans-serif;
  display: flex;
  gap: 1rem;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0;
  color: #384E4C;
}
.electricyt-page-input-contanier {
  display: flex;
  gap: 1.5rem;
}
.electricity-page-input-row {
  display: flex;
  gap: 0.5rem;
}
.total-title {
  letter-spacing: 3.6px;
  line-height: 18px;
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
}
.total-contanier {
  border-radius: 24px;
  font-size: 18px;
  line-height: 36px;
  background-color: #ffffff;
  padding: 3rem;
  border-radius: 12px;
}
.total-contanier h1{
  margin-block-start: 1.4px;

}
.total-total {
  font-weight: bold;
  font-family: "DM Sans", sans-serif;
  display: flex;
  justify-content: space-between;
}
.total-element {
  font-family: "DM Sans", sans-serif;
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  font-weight: lighter;
}
.total {
  min-width: 290px;
}
.dynamic-page select {
  width: 390px;
  height: 40px;
  border-radius: 10px;
  background-color: rgb(247, 249, 249);
  border: 1px solid rgb(239, 236, 235);
  outline: none;
  font-size: 18px;
  font-family: "DM Sans", sans-serif;
font-weight: 200;
}
.total-page {
  min-width: 395px;
}
.total-page-row {
  display: flex;
  gap: 2rem;
}
.total-button {
  font-family: "DM Sans", sans-serif;
  padding: 16px 10px;
  font-size: 18px;
  line-height: 24px;
  width: 20.55rem;
  border: 2px solid transparent;
  cursor: pointer;
  font-weight: bold;
  border-radius: 12px;
  color: white;
  background-color: #6E79FF;
}
.total-button:hover {
  background-color: #414ff2;
}
@media only screen and (max-width: 1100) {
  .total {
    margin-top: 2rem;
  }
}
@media only screen and (max-width: 777px) {
  .page-handler {
    align-items: center;
  }
}
@media only screen and (max-width: 460px) {
  .electricyt-page-input-contanier {
    flex-direction: column;
    gap: 0.5rem;
  }
  .dynamic-page input {
    margin-bottom: 0.5rem;
    width: 70vw;
  }
  .dynamic-page select {
    width: 70vw;
  }
  .page-switching-buttons button{
    width: 70vw;
  }
}
@media only screen and (max-width: 420px) {
  .page-title{
    font-size: 24px;
  }
  .dynamic-page{
    min-width: 295px;
  }
  .total-page {
    min-width: 295px;
  }
  .total-element{
    font-size: 16px;
  }
}
.main-title{
  font-size: 32px;
  font-weight: bold;
  margin-block-start: 0px;
}
.total-title-head{
  font-size: 32px;
  font-weight: bold;
  padding-top: .5rem;
}